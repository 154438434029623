
/* styles.css */

@font-face {
    font-family: 'Rabar_041';
    src: url('Rabar_041.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Rabar_042';
    src: url('Rabar_042.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Rabar_043';
    src: url('Rabar_043.ttf') format('truetype');
  }
  
  .font-kurdish-041 {
    font-family: 'Rabar_041', sans-serif;
  }
  
  .font-kurdish-042 {
    font-family: 'Rabar_042', sans-serif;
  }
  
  .font-kurdish-043 {
    font-family: 'Rabar_043', sans-serif;
  }
  