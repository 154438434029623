* {
  min-width: 0;
  min-height: 0;
}

:root {
  --color-bg: #18191a;
  --color-bg-variant: #1c1e21;
  --color-primary: #4d3db0;
  --color-primary-variant: #b0b3b8;
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.6);

  --transition: all 400ms ease;

  --swiper-theme-color: white !important;
}
body {
  background-color: white;
}
.br {
  border: 1px solid red;
}
.center{
  display: flex;
  justify-content: center;
  align-items: center;
}
::-webkit-scrollbar{
  display: none;
}
/* .TopLogin{
  background-color: var(--color-primary);

  background-image: url('https://i.pinimg.com/564x/9f/e3/01/9fe30127806487440ab6a36ee165d58e.jpg');
  background-blend-mode: lighten;
  background-size: cover;
} */